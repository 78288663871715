import React, { useContext } from 'react'
import UserContext from '../../../../contexts/UserContext.js'
import toast from 'react-hot-toast';
import PlaneText_wb from '../toast/planeText_wb.js'

import { motion } from 'framer-motion'
export default function Deletion() {
  const { setDeleteModal, deleteModal,deleteLink } = useContext(UserContext);
  console.log("deletion model rendered")
  const cancel = () => {
    console.log("cancel called")
    if (deleteModal) {
      setDeleteModal(false)
    }
  }
  const delete_ = () => {
    //perform delete operation on data base
    //  .. ....  ..




    //push notification
    toast.success(<PlaneText_wb msg={"Link deleted: \"" + deleteLink + "\""} />, {
      duration: 2600,
      position: 'bottom-right',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    })

    if (deleteModal) {
      setDeleteModal(false)
    }
  }
  return (
    <motion.div onClick={(e)=>e.stopPropagation()}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      style={{
        position: "relative",
        background: "rgb(208, 213, 232)",
        height: 141,
        width: 382,
        borderRadius: 3,
        padding: "34px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <button onClick={cancel}
        style={{
          position: "absolute",
          top: 5,
          right: 5,
          cursor: "pointer",
          padding: "4px 9px",
          borderRadius: 19,
          fontWeight: 600,
          color: "rgb(239, 230, 230)",
          background: "rgb(67, 67, 107)",
          fontSize: "75%"
        }}
      >
        X
      </button>
      <div style={{}}>Are you want to delete Link  "<b>{deleteLink}</b>"</div>
      <div
        style={{ marginTop: 10, display: "flex", justifyContent: "space-evenly" }}
      >
        <button onClick={delete_}
          className="bg-slate-600 text-slate-100 hover:bg-slate-600 hover:text-slate-100 "
          style={{ borderRadius: 3, padding: "2px 10px" }}
        >
          Confirm
        </button>
        <button onClick={cancel}
          className="bg-slate-600 text-slate-100 hover:bg-slate-600 hover:text-slate-100 "
          style={{ borderRadius: 3, padding: "2px 29px" }}
        >
          No
        </button>
      </div>
    </motion.div>

  )
}
