import React from 'react'

export default function PlaneText_wb({ msg }) {
    return (
        <span style={{ fontWeight: 600, padding: 10, fontSize: "130%" }}>
            {msg}

        </span>
    )
}
