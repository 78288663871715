import React from 'react'
import Links from './Elements/links.js'
import data from '../data/dummy.js'

import { motion } from 'framer-motion'

export default function Recents() {

    console.log(data.links);

    let sorted_links = data.links.sort((a, b) =>
        b.absolute_time - a.absolute_time
    );

    return (

        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
         className=" flex flex-wrap flex-row">
            {sorted_links.map((link, index) => <Links key={index} data={link} />)}


        </motion.div>

    )
}
