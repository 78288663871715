import React, { useState } from 'react'
import toast from 'react-hot-toast';

import PlaneText_wb from './toast/planeText_wb.js'
import { useContext } from "react";
import UserContext from '../../../contexts/UserContext.js'
export default function Links(props) {
    const [imp, setImp] = useState(props.data.important);
    const { bookModal, setBookModal, setSelectedBook ,setDeleteModal,deleteModal,setDeleteLink } = useContext(UserContext);

    const showBooks = () => {
        if (!bookModal) {
            setBookModal(true);
            setSelectedBook(props.data.book)
        }
    }
    const confirm_deletion = () => {
        
        if (!deleteModal) {
            setDeleteModal(true);
            setDeleteLink(props.data.title)
        }
    }

    const copyClicked = () => {
        toast.success(<PlaneText_wb msg="Link Copied to clipboard" />, {
            duration: 1600,
            position: 'bottom-right',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
        navigator.clipboard.writeText(props.data.link);
    }
    
    const starClicked = () => {
        if (!imp) {
            toast.success(<PlaneText_wb msg="Added to Importants" />, {
                duration: 1600,
                position: 'bottom-right',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            // program for updation
            //   ....
        } else {
            toast.error(<PlaneText_wb msg="Removed from Importants" />, {
                duration: 1200,
                position: 'bottom-right',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            // program for updation
            //   ....
        }
        setImp(!imp);
    }
    return (
        <>

            <div style={{ position: "relative", margin: 20, maxWidth: 285, padding: "13px 7px", borderRadius: 4, background: "#1f4566", boxShadow: "0px 0px 2px 1px #1f4566" }}            >
                <div style={{zIndex:1, position: "absolute", width: 32, top: "-10px", left: "-16px" }}>
                    {imp && <img src="https://cdn.iconscout.com/icon/free/png-128/star-bookmark-favorite-shape-rank-3-16006.png" />}
                </div>
                <div
                    style={{ position: "absolute", width: 32, top: "-10px", left: "-16px" }}
                />
                <div style={{ position: "relative", display: "inline-block" }}>
                    <a target="_blank" href={props.data.link} style={{}} >
                        <img title="Click to open the link" src={props.data.image} alt="" style={{ height: 150, width: 260, objectFit: "cover", borderRadius: 3, margin: "auto" }} />
                        <div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", position: "absolute", bottom: 0, background: "rgba(0, 0, 0, 0.59)", borderRadius: "0px 0px 2px 2px", fontSize: "80%" }}     >
                            <img src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${props.data.domain}/&size=256`} style={{ width: 22, borderRadius: 7, display: "inline", margin: "3px 6px" }} />{" "}         {props.data.domain}
                        </div>
                    </a>
                </div>

                <div style={{ margin: "0px 0px 0px", position: "relative" }}>
                    <button className="bg-slate-400 hover:bg-slate-300" onClick={copyClicked}
                        style={{ float: "right", fontSize: "83%", color: "wheat", fontWeight: 600, borderRadius: 5, marginLeft: 9 }} >     <img src="https://cdn.iconscout.com/icon/free/png-128/copy-clone-content-duplicate-layer-30491.png" width={25} height={25} style={{ width: 25, padding: 2 }} /> </button>
                    <button className="bg-slate-400 hover:bg-slate-300" onClick={starClicked}
                        style={{ float: "right", fontSize: "83%", color: "wheat", fontWeight: 600, borderRadius: 5, marginLeft: 9 }} >     <img src="https://cdn.iconscout.com/icon/free/png-128/star-2456525-2036055.png" width={25} height={25} style={{ padding: 3 }} /> </button>
                    <button onClick={confirm_deletion} className="bg-slate-400 hover:bg-slate-300"
                        style={{ float: "left", fontSize: "83%", color: "wheat", fontWeight: 600, borderRadius: 5 }} >     <img src="https://cdn.iconscout.com/icon/free/png-128/delete-737-475058.png" width={25} height={25} style={{ padding: 2 }} /> </button>
                    <button onClick={showBooks} className="bg-slate-400 hover:bg-slate-300"
                        style={{ float: "right", fontSize: "83%", color: "rgb(0, 255, 0)", fontWeight: 600, borderRadius: 5, padding: 1 }} >     <img src="https://cdn.iconscout.com/icon/free/png-256/add-1468-475004.png" width={23} height={23} /> </button>
                </div>
                <div
                    style={{ textAlign: "justify", fontSize: "113%", padding: "29px 0px 0px 0px", color: "antiquewhite", fontWeight: 500 }} > {props.data.title} </div>
                <div style={{ padding: "1px 0px 6px" }}>
                    <div
                        style={{ fontSize: "75%", fontWeight: 500, color: "rgb(238, 250, 251)", position: "absolute", right: 9, bottom: 1 }}    >     {props.data.date} </div>
                </div>
            </div>














        </>
    )
}