import data from '../components/data/dummy.js'



let map__freq = new Map();
let map__latestUpdate = new Map();
let map__icons = new Map();
let link__arr = data.links;

for (let i = 0; i < link__arr.length; i++) {
    if (map__freq.has(link__arr[i].book)) {
        map__freq.set(link__arr[i].book, map__freq.get(link__arr[i].book) + 1)
        if (map__latestUpdate.get(link__arr[i].book).abs < link__arr[i].absolute_time) {
            map__latestUpdate.set(link__arr[i].book, { abs: link__arr[i].absolute_time, date: link__arr[i].date });
        }

        map__icons.get(link__arr[i].book).push(link__arr[i].domain);
    } else if (link__arr[i].book != null) {
        map__freq.set(link__arr[i].book, 1);
        map__latestUpdate.set(link__arr[i].book, { abs: link__arr[i].absolute_time, date: link__arr[i].date });

        map__icons.set(link__arr[i].book, [link__arr[i].domain]);

    }
}

let books = [];

map__freq.forEach((value, key) => {
    books.push({
        book: key,
        count: map__freq.get(key),
        update: map__latestUpdate.get(key).date,
        icons: map__icons.get(key)
    });
});
books.sort((a, b) => (a.book > b.book) ? 1 : ((b.book > a.book) ? -1 : 0));
export let books__arr = books.map((book) =>
    book.book
)
console.log(books__arr)
export default books;