import React from 'react'
import { useContext } from "react";
import UserContext from '../../contexts/UserContext.js'
import Links from './Elements/links.js'
import Domain from './Elements/domains.js'
import Book from './Elements/books.js'
import data from '../data/dummy.js'

import { motion } from 'framer-motion'

export default function Search() {
    const { domains, books, query } = useContext(UserContext);
    if (query.length > 0) {
        let filtered_domains = [];
        let filtered_books = [];
        let filtered_links = [];
        data.links.forEach((link) => {
            if (link.title.toLowerCase().includes(query.toLowerCase()))

                filtered_links.push(link)
        })
        console.log(filtered_links);
        books.forEach((book) => {
            if (book.book.toLowerCase().includes(query.toLowerCase()))
                filtered_books.push(book);
        })
        domains.forEach((domain) => {
            if (domain.domain.toLowerCase().includes(query.toLowerCase()))
                filtered_domains.push(domain);
        })
        if (filtered_domains.length === 0 && filtered_books.length === 0 && filtered_links.length === 0) {
            console.log("Not found should be shown")
            return (
                <div style={{display: "flex",minHeight:"90vh",                flexDirection: "row",                flexWrap: "wrap",                alignItems: "center",                justifyContent: "center",                fontWeight: 500,}}>                    <img src="https://cdni.iconscout.com/illustration/premium/thumb/webpage-not-found-5057984-4217813.png" alt="" />
                    <p style={{fontSize: "250%",    fontWeight: 500,}}>Not Found</p>
                </div>
            )
        } else
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} style={{ display: query.length > 0 ? "block" : "none",minHeight: "77vh" }}>
                    <div className=" flex flex-wrap flex-row">
                        {filtered_books.map((book, index) => <Book key={index} book={book.book} count={book.count} update={book.update} icons={book.icons} />)}
                    </div>
                    <div className=" flex flex-wrap flex-row">
                        {filtered_domains.map((domain, index) => <Domain key={index} domain={domain.domain} count={domain.count} update={domain.update} />)}
                    </div>
                    <div className=" flex flex-wrap flex-row">
                        {filtered_links.map((link, index) => <Links key={index} data={link} />)}
                    </div>
                </motion.div>
            )
    }
}