import React from 'react'
import { Link } from "react-router-dom";
import { useContext } from "react";
import UserContext from '../../../contexts/UserContext.js'

export default function Books(props) {
  const { setQuery } = useContext(UserContext);

  console.log(props)
  let icons__arr = props.icons;
  let map__freq = new Map();
  for (let i = 0; i < props.icons.length; i++) {
    if (map__freq.has(icons__arr[i])) {
      map__freq.set(icons__arr[i], map__freq.get(icons__arr[i]) + 1)
    }
    else if (icons__arr[i] != null) {
      map__freq.set(icons__arr[i], 1);
    }
  }
  const mapSort1 = new Map([...map__freq.entries()].sort((a, b) => b[1] - a[1]));

  let icon__5 = []; let count = 0;
  mapSort1.forEach((value, key) => {
    if (count === 6) return;
    icon__5.push(key); count++;

  });

  console.log(mapSort1);

  return (
    <>
      {/* <div
        style={{ position: "relative", margin: 20, maxWidth: 421, padding: 20, borderRadius: 7, backgroundImage: "linear-gradient(43deg, #06bbcf, #7b2de6)" }}    >
        <Link onClick={() => setQuery("")} to={{ pathname: `../filtered/books-${props.book.replaceAll(" ", "__")}` }} style={{ position: "absolute", bottom: 9, right: 13, background: "#0eb0d0", color: "#f3f0f8", padding: "5px 12px", borderRadius: 3, fontWeight: 500 }}      >  Read      </Link>
        <div style={{ marginTop: 5, width: 440, fontSize: "150%", color: "antiquewhite", fontWeight: 500 }}      >  {props.book}    </div>
        <div style={{ fontWeight: 500, color: "azure" }}>  Total links : <span style={{ color: "blanchedalmond" }}>{props.count}</span>      </div>      <div style={{ fontWeight: 500, color: "azure" }}>
          Last updated :{" "}
          <span style={{ color: "blanchedalmond" }}>{props.update}</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row-reverse", flexWrap: "wrap", width: 173, position: "absolute", top: 6, right: 13 }}      >
          {icon__5.map((icon) => <img style={{ width: 30, background: "#dfe3e7", margin: "0px 2px", borderRadius: 6 }} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${icon}/&size=256`} alt="" />)}

        </div>

      </div> */}



      <Link onClick={() => setQuery("")} to={{ pathname: `../filtered/books-${props.book.replaceAll(" ", "__")}` }}
        style={{ position: "relative", margin: 20, maxWidth: 380, padding: "15px 7px", borderRadius: 7, textAlign: "inherit", backgroundImage: "linear-gradient(43deg, rgb(11 182 208), rgb(70 18 154))", boxShadow: "0px 0px 4px 1px #1b568b" }}      >
        <div style={{ margin: "13px 0px", width: 378, fontSize: "110%", color: "antiquewhite", fontWeight: 500 }}        >          {props.book}        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly", width: 290, position: "absolute", right: "-15px", bottom: 0 }}        >
          <div style={{ fontWeight: 500, color: "azure", display: "flex", position: "relative" }}          >  {" "}
            <img src="https://cdn.iconscout.com/icon/free/png-128/link-34-100474.png" alt="" style={{ width: 25 }} />{" "}  <span style={{ color: "#cad4d8", position: "absolute", left: 32 }}>    {props.count}  </span>{" "}          </div>
          <div style={{ color: "azure", display: "flex", alignItems: "center" }}          >  <img src="https://cdn.iconscout.com/icon/free/png-128/upload-2833107-2381964.png" alt="" style={{ width: 25 }} />{" "}  <span
            style={{              color: "#cad4d8",              position: "relative",             left: 5,              fontSize: "90%",              fontWeight: 500            }}          >            {props.update}          </span>          </div>        </div>
        <div style={{ display: "flex", flexFlow: "row-reverse wrap", width: 173, position: "absolute", top: 6, right: 13 }} >
          {icon__5.map((icon) => <img style={{ width: 30, background: "rgb(223, 227, 231)", margin: "0px 2px", borderRadius: 6 }} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${icon}/&size=256`} alt="" />)}

        </div>
      </Link>

    </>
  )
}
