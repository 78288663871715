import React from 'react'
import Book from './Elements/books.js'
import { useContext } from "react";
import UserContext from '../../contexts/UserContext.js'
import { motion } from 'framer-motion'
export default function Books_collection() {

  const { books, } = useContext(UserContext);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} className="flex flex-wrap ">
      {books.map((book, index) => <Book key={index} book={book.book} count={book.count} update={book.update} icons={book.icons} />)}

    </motion.div>

  )
}
