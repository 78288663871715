import React from 'react'
import {  Link } from "react-router-dom";
import { useContext } from "react";
import UserContext from '../../../contexts/UserContext.js'

export default function Domains(props) {
  const { setQuery } = useContext(UserContext);

  return (
    <>
    {/* <div
      style={{
        position: "relative",
        margin: 20,
        maxWidth: 420,
        padding:"6px 20px",
        borderRadius: 7,
        backgroundImage: "linear-gradient(43deg, #06bbcf, #7b2de6)"
      }}
    >
      <Link  onClick={()=>setQuery("")} to={{ pathname: `../filtered/domains-${props.domain.split("//")[1]}`}}
        style={{
          position: "absolute",
          bottom: 9,
          right: 30,
          background: "#0eb0d0",
          color: "#f3f0f8",
          padding: "5px 25px",
          borderRadius: 3,
          fontWeight: 500
        }}
      >
        View
      </Link>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          position: "relative",
          right: 15
        }}
      >
        <img
          src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${props.domain}/&size=256`}
          alt=""
          style={{ width: 68,borderRadius:7 , marginRight:12 ,marginBottom:7}}
        />
        <div
          style={{
            width: 302,
            fontSize: "129%",
            color: "antiquewhite",
            fontWeight: 500
          }}
        >
          {props.domain.split("//")[1]}
        </div>
      </div>
      <div style={{ fontWeight: 500, color: "azure" }}>
        Total links : <span style={{ color: "blanchedalmond" }}>{props.count}</span>
      </div>
      <div style={{ fontWeight: 500, color: "azure" }}>
        Last updated :{" "}
        <span style={{ color: "blanchedalmond" }}>{props.update}</span>
      </div>
    </div>

 */}




    <Link    onClick={()=>setQuery("")} to={{ pathname: `../filtered/domains-${props.domain.split("//")[1]}`}}
  style={{
    position: "relative",
    margin: 20,
    maxWidth: 380,
    padding: "7px 7px",
    borderRadius: 7,
    /* backgroundImage: 'linear-gradient(43deg, rgb(68 84 85), rgb(123, 45, 230))', */ textAlign:
      "inherit",
    backgroundImage: "linear-gradient(43deg, rgb(11 182 208), rgb(70 18 154))",
    boxShadow: "0px 0px 4px 1px #1b568b"
  }}
>
  <div
    style={{
      /* margin: '13px 0px', */ width: 378,
      fontSize: "130%",
      color: "antiquewhite",
      fontWeight: 500
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      {" "}
      <img
        src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${props.domain}/&size=256`}
        alt=""
        style={{borderRadius:4, width: 68, marginRight: 13 }}
      />
      <p>{props.domain.split("//")[1]} </p>
    </div>
  </div>
  <div
    style={{
      display: "flex",
      justifyContent: "space-evenly",
      width: 246,
      position: "absolute",
      right: "-15px",
      bottom: 0
    }}
  >
    <div
      style={{
        fontWeight: 500,
        color: "azure",
        display: "flex",
        position: "relative"
      }}
    >
      {" "}
      <img
        src="https://cdn.iconscout.com/icon/free/png-128/link-34-100474.png"
        alt=""
        style={{ width: 25 }}
      />{" "}
      <span style={{ color: "#cad4d8", position: "absolute", left: 32 }}>
      {props.count}
      </span>
    </div>
    <div
      style={{
        /* fontWeight: 500, */ color: "azure",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img
        src="https://cdn.iconscout.com/icon/free/png-128/upload-2833107-2381964.png"
        alt=""
        style={{ width: 25 }}
      />{" "}
      <span
        style={{
          color: "#cad4d8",
          position: "relative",
          left: 5,
          fontSize: "90%",
          fontWeight: 500
        }}
      >
     {props.update}
      </span>
    </div>
  </div>
</Link>

    </>
  )
}
