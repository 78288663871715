import React from 'react'
import { useContext } from "react";
import Domain from './Elements/domains.js'
import UserContext from '../../contexts/UserContext.js'
import { toast } from 'react-hot-toast'
import PlaneText_wb from './Elements/toast//planeText_wb.js'

import { motion } from 'framer-motion'


export default function Domains_collection() {
  const { domains } = useContext(UserContext);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} className="flex flex-wrap ">
      {domains.map((domain, index) => <Domain key={index} domain={domain.domain} count={domain.count} update={domain.update} />)}
    </motion.div>

  )
}