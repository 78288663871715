import React, { useContext } from 'react'
import { Outlet, Link } from "react-router-dom";
import UserContext from '../../contexts/UserContext.js'
export default function Navbar_after_login() {
    // const [search, setSearch] = useState();
    const { query, setQuery } = useContext(UserContext);

    return (
        <>
            <div style={{ display: "flex", backgroundImage: "linear-gradient(43deg, #06bbcf, #7b2de6)", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: "3px 19px" }}    >
                <Link onClick={() => setQuery("")} to="upload" style={{ color: "#662adf", fontWeight: 500, padding: "6px 6px", borderRadius: 16, backgroundImage: "linear-gradient(91deg, #ffffff61, #e1e1e1c7)", width: 98, display: "flex", alignItems: "center" }}    >
                    <img src="https://cdn.iconscout.com/icon/free/png-128/bookmark-1754138-1493251.png" alt="" style={{ width: 30 }} />
                    <p>Upload</p>
                </Link>
                <div style={{ position: "relative" }}>
                    <input required value={query} onChange={(e) => setQuery(e.target.value)} type="text" name="" id="" placeholder="🔎  Search..." style={{ paddingLeft: 10, border: "none", borderRadius: 3, height: 36, width: 250 }} />
                    <button style={{ fontWeight:500, padding: "0px 9px 3px 8px", borderRadius: 4, color: "#0000007d", position: "absolute", right: 2, bottom: 5 }} onClick={() => setQuery("")}>x</button>
                    {/* <Link to={`filtered/search-${query}`} style={{ color: "aliceblue", background: "#09b7cf", padding: "6px 7px", borderRadius: 3, marginLeft: 7, fontSize: "90%", fontWeight: 500 }}   >
                        Search
                    </Link> */}
                </div>
                <div style={{ marginTop: 10, width: 299, display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", fontWeight: 500, color: "antiquewhite" }}    >
                    <Link onClick={() => setQuery("")} to="/">Recent</Link>
                    <Link onClick={() => setQuery("")} to="/important">Important</Link>
                    <Link onClick={() => setQuery("")} to="/books">Books</Link>
                    <Link onClick={() => setQuery("")} to="/domains">Sites</Link>
                </div>
            </div>
            <div style={{ display: query.length > 0 ? "none" : "block", minHeight: "80vh", }}>
                <Outlet />

            </div>
        </>
    )
}
