import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserContext from './contexts/UserContext.js'
import books from './utility/arr__books.js'
import domains from './utility/arr__domains.js'
import Home from './components/views/Home.js'
import Domains_collection from './components/views/Domains_collection.js'
import Upload from './components/views/upload.js'
import Books_collection from './components/views/Books_collection.js'
import Important_collection from './components/views/Important_collection.js'
import Recent from './components/views/Recents.js'
import Filtered from './components/views/Filtered.js'
import Search from './components/views/Search.js'
import Footer from './components/layouts/Footer.js'
import Navbar_after_login from './components/layouts/Navbar_after_login.js'
import Navbar_before_login from './components/layouts/Navbar_before_login.js'
import { AnimatePresence } from 'framer-motion'
import { Toaster } from 'react-hot-toast'
import Modal_books from './components/views/Elements/modal/books.js'
import Modal_delete from './components/views/Elements/modal/deletion.js'

export default function App() {

  const [query, setQuery] = useState("");
  const [bookModal, setBookModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedLink, setDeleteLink] = useState(null);

  let logged = true;
  console.log(books)
  console.log(domains)
  return (
    <div className="bg-gray-100" >
      <AnimatePresence>
        <BrowserRouter>
          <UserContext.Provider value={{
            books: books,
            domains: domains,
            setQuery: setQuery,
            query: query,

            bookModal: bookModal,
            setBookModal: setBookModal,

            deleteModal: deleteModal,
            setDeleteModal: setDeleteModal,

            setSelectedBook: setSelectedBook,
            selectedBook: selectedBook,

            deleteLink: deletedLink,
            setDeleteLink: setDeleteLink,
          }} >
            <Routes>
              <Route path="/" element={logged ? <Navbar_after_login /> : <Navbar_before_login />}>
                <Route index element={logged ? <Recent /> : <Home />} />
                <Route path="books" element={logged ? <Books_collection /> : <Home />} />
                <Route path="domains" element={logged ? <Domains_collection /> : <Home />} />
                <Route path="important" element={logged ? <Important_collection /> : <Home />} />
                <Route path="upload" element={logged ? <Upload /> : <Home />} />
                <Route path="filtered/:params" element={logged ? <Filtered /> : <Home />} />
              </Route>
            </Routes>
            <Search />
            <Footer />
            {bookModal &&
              <div onClick={() => setBookModal(false)} style={{ position: "fixed", display: "flex", top: 0, left: 0, width: "100%", height: "100vh", background: "#000000c7", justifyContent: "center", alignItems: "center" }}> <Modal_books /> </div>
            }
            <div style={{ background: "rgb(31 38 44)", boxShadow: "rgb(55 62 69) 0px 0px 4px 3px" }}><Toaster /></div>
            {deleteModal &&
              <div onClick={() => setDeleteModal(false)}  style={{ position: "fixed", display: "flex", top: 0, left: 0, width: "100%", height: "100vh", background: "#000000c7", justifyContent: "center", alignItems: "center" }}> <Modal_delete /> </div>
            }
            <div style={{ background: "rgb(31 38 44)", boxShadow: "rgb(55 62 69) 0px 0px 4px 3px" }}><Toaster /></div>
          </UserContext.Provider>
        </BrowserRouter>
      </AnimatePresence>
    </div >
  )
}

