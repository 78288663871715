import React from 'react'
import { useContext, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion'
import toast from 'react-hot-toast';
import PlaneText_wb from '../toast/planeText_wb.js'
import bookData,{books__arr} from '../../../../utility/arr__books'
import UserContext from '../../../../contexts/UserContext.js'
export default function Books() {
  const { setBookModal, selectedBook, setSelectedBook } = useContext(UserContext);
  const [bookName, setBookName] = useState("")

  console.log("books rendered")
  const addBook = (book) => {
    console.log(book, " selected")

    //perform  operation on data base
    //  .. ....  ..

    //push notification
    toast.success(<PlaneText_wb msg={"Link added to \"" + book + "\""} />, {
      duration: 1600,
      position: 'bottom-right',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    })
    setSelectedBook(book)

  }
  const cancelBook = () => {
    setBookModal(false);
    // setSelectedBook("")

  }

  const addNewBook=()=>{
    books__arr.push(bookName)
    setBookName("")
    setSelectedBook(bookName)
  }
  return (
    <AnimatePresence>
      <motion.div onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        style={{ position: "relative", background: "#d0d5e8", display: "flex", flexDirection: "column", alignItems: "flex-start", maxWidth: 416, maxHeight: "66vh", minHeight: "50vh", minWidth: "371px", padding: "47px 38px 18px 24px", borderRadius: 4, margin: 40 }}    >

        <button onClick={cancelBook} style={{ position: "absolute", top: 5, right: 5, cursor: "pointer", padding: "4px 9px", borderRadius: 19, fontWeight: 600, color: "rgb(239, 230, 230)", background: "#43436b", fontSize: "75%" }}> X </button>
        {selectedBook!="" && <button onClick={()=>setSelectedBook("")} style={{ position: "absolute", top: 47, right: 35, cursor: "pointer", padding: "4px 9px", borderRadius: 3, fontWeight: 600, color: "rgb(239, 230, 230)", background: "rgb(37 93 113)", fontSize: "75%" }}> Unselect </button>}
        <div style={{ display: "flex" }}>
          <input style={{ paddingLeft: 7, width: 171, borderRadius: 3, height: 27 }} type="text" placeholder="Add new book" onChange={(e) => setBookName(e.target.value)} value={bookName} />
          <AnimatePresence>
            {bookName != "" &&
              <motion.button onClick={addNewBook}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                style={{ background: "rgb(36 93 113)", padding: "2px 17px", borderRadius: 3, marginLeft: 7, color: "#1c1d1f", fontSize: "75%", color: "aliceblue",fontWeight:600 }}
              >
                Save
              </motion.button>
            }
          </AnimatePresence>
          {bookName != "" && <button style={{
            position: "relative",
            right: 89,
            fontSize: "63%",
            background: "rgb(195 198 209 / 58%)",
            padding: "0px 6px",
            height: 21,
            borderRadius: 9,
            top: 3
          }} onClick={() => setBookName("")}>X</button>}
        </div>
        {books__arr.map((book) =>
          <button value={book} onClick={(e) => addBook(e.target.value)} className={(book === selectedBook ? "bg-slate-600 text-slate-100 " : " text-slate-800 bg-slate-200 ") + (" hover:bg-slate-600 hover:text-slate-100 ")} style={{ marginTop: 6, width: "100%", textAlign: "inherit", padding: "1px 7px", borderRadius: 3 }} >
            {book}
          </button>
        )}

      </motion.div>
    </AnimatePresence >
  )
}
