import React from 'react'
import Links from './Elements/links.js'
import Domain from './Elements/domains.js'
import Book from './Elements/books.js'
import data from '../data/dummy.js'

import { motion } from 'framer-motion'
import { useContext, useState } from "react";
import UserContext from '../../contexts/UserContext.js'

export default function Recents(props) {

    console.log(window.location.pathname.split("/")[2].split("-"));


    let key = window.location.pathname.split("/")[2].split("-")[1].replaceAll("__", " ");
    let type = window.location.pathname.split("/")[2].split("-")[0];

    console.log(key, type)
    let filtered__links = [];
    if (type === "domains") {

        filtered__links = data.links.filter(function (link) { return link.domain.split("//")[1] === key });

    } else if (type === "books") {

        filtered__links = data.links.filter(function (link) { return link.book === key.replaceAll("__", " ") });

    } else if (type === "search") {
        //  ..... search algorithm
       
    }
    return (
        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}className=" flex flex-wrap flex-row">
            {filtered__links.map((link,index) => <Links key={index} data={link} />)}
        </motion.div>
    )

}
