import Links from './Elements/links.js'
import data from '../data/dummy.js'
import { motion } from 'framer-motion'

export default function Important_collection() {
  
  console.log(data.links);
  console.log("iside important")
  return (

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} className=" flex flex-wrap flex-row">
      {data.links.map(
        (link, index) => { return link.important && <Links key={index} data={link} /> }
      )}
    </motion.div>


  )
}
