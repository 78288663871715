import data from '../components/data/dummy.js'



let map__freq = new Map();
let map__latestUpdate = new Map();
let link__arr = data.links;

for (let i = 0; i < link__arr.length; i++) {
    if (map__freq.has(link__arr[i].domain)) {
        map__freq.set(link__arr[i].domain, map__freq.get(link__arr[i].domain) + 1)
        if (map__latestUpdate.get(link__arr[i].domain).abs < link__arr[i].absolute_time) {
            map__latestUpdate.set(link__arr[i].domain, { abs: link__arr[i].absolute_time, date: link__arr[i].date });
        }
    } else {
        map__freq.set(link__arr[i].domain, 1);
        map__latestUpdate.set(link__arr[i].domain, { abs: link__arr[i].absolute_time, date: link__arr[i].date });

    }
}
let domains = [];

map__freq.forEach((value, key) => {
    domains.push({
        domain: key,
        count: map__freq.get(key),
        update: map__latestUpdate.get(key).date
    });
});
domains.sort((a, b) => (a.domain > b.domain) ? 1 : ((b.domain > a.domain) ? -1 : 0));

export default domains;